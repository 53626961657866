import {
  Box,
  Link,
  useMediaQuery,
  useTheme,
  Icon,
  Grid,
  Divider,
  Typography,
} from "@mui/material";

import YouTubeIcon from "@mui/icons-material/YouTube";
// import WhatsappIconSVG from "../icons-svg/Whatsapp-Icon-SVG";
import WhatsappIconSVG from "../../assets/whatsapp-icon.svg";

interface IFooterProps {
  type?: string | undefined;
  background?: string;
}

export const FooterVagisil: React.FC<IFooterProps> = ({ type, background }) => {
  const theme = useTheme();
  const { spacing, breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <Box
      sx={{
        background: background ?? palette.tertiary.main,
        paddingY: spacing(6),
      }}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      maxHeight={smDown ? spacing(30) : spacing(20)}
      mt="auto"
    >
      <Box
        sx={{
          maxWidth: "1460px",
          width: "100%",
          paddingX: smDown ? spacing(4) : spacing(8),
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              height="100%"
            >
              <Box display="flex" mb={3}>
                <img
                  src={WhatsappIconSVG}
                  style={{ width: "20px", height: "20px" }}
                  alt="WhatsApp_icon"
                />
                <Link
                  ml={2}
                  href="https://wa.me/5511994889502"
                  underline="none"
                  variant="body2"
                  color="white"
                  target="_blank"
                >
                  SAC - (11) 99488-9502
                </Link>
              </Box>

              <Box display="flex" mb={3}>
                <Icon fontSize="small" sx={{ color: "white" }}>
                  local_phone
                </Icon>

                <Typography ml={2} variant="body2" color="white">
                  Atendimento ao cliente 0800-011-16-46
                </Typography>
                {/* 
                <Link
                  ml={2}
                  href="#"
                  underline="none"
                  variant="body2"
                  color="white"
                >
                  Atendimento ao cliente 0800-011-16-46
                </Link> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Divider
              orientation={smDown ? "horizontal" : "vertical"}
              color="white"
              sx={{ width: smDown ? "100%" : "0px" }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              ml={4}
            >
              <Box display="flex" mb={3}>
                <Link
                  href="https://www.vagisil.com/pt-br/our-story"
                  underline="none"
                  variant="body2"
                  color="white"
                  target="_blank"
                >
                  Conheça nossa marca
                </Link>
              </Box>

              <Box mb={3}>
                <Link
                  href="https://www.youtube.com/user/Vagisil"
                  underline="none"
                  variant="body2"
                  color="white"
                  target="_blank"
                >
                  <YouTubeIcon sx={{ color: "white" }} />
                </Link>
              </Box>

              <Box>
                <Link
                  href="https://www.vagisil.com/pt-br/faq"
                  underline="none"
                  variant="body2"
                  color="white"
                  target="_blank"
                >
                  Perguntas frequentes
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
