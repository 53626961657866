import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useDrawerContext } from "../shared/contexts";
import { Dashboard, Products } from "../pages";
import { ProductsView } from "../pages/new-product-view/products";

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        label: "Pagina inicial",
        icon: "home",
        path: "/home",
      },
      {
        label: "Product",
        icon: "category",
        path: "/vagisilwashob",
      },
      {
        label: "Product",
        icon: "category",
        path: "/vagisilsprayob",
      },
      {
        label: "Product",
        icon: "category",
        path: "/deoph",
      },
      {
        label: "Product",
        icon: "category",
        path: "/deojasminbranco",
      },
      {
        label: "Product",
        icon: "category",
        path: "/deoflordepessegueiro",
      },
      {
        label: "Product",
        icon: "category",
        path: "/washurinprotect",
      },
    ]);
  }, []);

  return (
    <Routes>
      {/* <Route path="/home" element={<Dashboard />} /> */}
      {/* <Route path="/:path" element={<Products />} /> */}
      <Route path="/:path" element={<ProductsView />} />
      <Route path="*" element={<Navigate to="/vagisilsprayob" />} />
    </Routes>
  );
};
