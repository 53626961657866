import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import pink_card_1 from "../../../../shared/assets/cards/pink_card_1.svg";
import light_blue_card_1 from "../../../../shared/assets/cards/light_blue_card_1.svg";
import yellow_card_1 from "../../../../shared/assets/cards/yellow_card_1.svg";
import orange_card_1 from "../../../../shared/assets/cards/orange_card_1.svg";
import turquoise_card_1 from "../../../../shared/assets/cards/turquoise_card_1.svg";

import odorBlock from "../../../../shared/assets/icons_odorBlock_1.png";
import soothesSkin from "../../../../shared/assets/icons_soothesSkin_2.png";
import noWhiteResidue from "../../../../shared/assets/icons_noWhiteResidue.png";
import noWhiteResidueBlue from "../../../../shared/assets/icons_noWhiteResidue_blue.svg";
import qualityIngredients from "../../../../shared/assets/icon_qualityIngredients.png";
import pHbalanced from "../../../../shared/assets/icons_pHbalanced_6.png";
import lightScent from "../../../../shared/assets/icons_lightScent.png";
import lightScentBlue from "../../../../shared/assets/icons_lightScent_blue.png";
import noFormaldehyde from "../../../../shared/assets/icons_noFormaldehyde_1.png";
import allDayFreshness from "../../../../shared/assets/icons_allDayFreshness.svg";
import allDayFreshnessBlue from "../../../../shared/assets/icons_allDayFreshness_blue.svg";

import "./styles.css";
import { useEffect, useState } from "react";

interface IContentCardProps {
  productId: number;
  color?: string;
}

type ArrayBox = {
  title: string;
  description: string;
  icon: string;
  bgImage: string;
};

const arrayVagisilOB = [
  {
    bgImage: pink_card_1,
    icon: odorBlock,
    title: "Exclusiva tecnologia Odor Block",
    description: "",
  },
  {
    bgImage: pink_card_1,
    icon: soothesSkin,
    title: "Bloqueia odores sem irritantes",
    description: "",
  },
  {
    bgImage: pink_card_1,
    icon: noWhiteResidue,
    title: "Nenhum resíduo branco",
    description: "",
  },
];

const arrayDeoph = [
  {
    bgImage: light_blue_card_1,
    icon: odorBlock,
    title: "Ajuda a previnir o odor",
    description: "",
  },
  {
    bgImage: light_blue_card_1,
    icon: pHbalanced,
    title: "Respeita o seu pH natural",
    description: "",
  },
  {
    bgImage: light_blue_card_1,
    icon: noFormaldehyde,
    title: "Sem corantes, álcool, antitranspirantes ou parabenos",
    description: "",
  },
];

const arrayDeoJasmimBranco = [
  {
    bgImage: yellow_card_1,
    icon: lightScentBlue,
    title: "Perfurme amigo da pele",
    description: "",
  },
  {
    bgImage: yellow_card_1,
    icon: allDayFreshnessBlue,
    title: "Frescura durante todo o dia",
    description: "",
  },
  {
    bgImage: yellow_card_1,
    icon: noWhiteResidueBlue,
    title: "Nenhum resíduo branco",
    description: "",
  },
];

const arrayDeoFlorDePessegueiro = [
  {
    bgImage: orange_card_1,
    icon: lightScent,
    title: "Perfurme amigo da pele",
    description: "",
  },
  {
    bgImage: orange_card_1,
    icon: allDayFreshness,
    title: "Frescura durante todo o dia",
    description: "",
  },
  {
    bgImage: orange_card_1,
    icon: noWhiteResidue,
    title: "Nenhum resíduo branco",
    description: "",
  },
];

const arrayWashUrinProtect = [
  {
    bgImage: turquoise_card_1,
    icon: soothesSkin,
    title: "Acalma a pele",
    description: "",
  },
  {
    bgImage: turquoise_card_1,
    icon: qualityIngredients,
    title: "Lava os resíduos de urina irritantes",
    description: "",
  },
  {
    bgImage: turquoise_card_1,
    icon: lightScent,
    title: "Aroma fresco e suave",
    description: "",
  },
];

const createVagisilOBConfigs = (palette: any) => {
  return {
    color: palette.tertiary.dark || "#EC5BA5",
    content_cards: arrayVagisilOB,
  };
};

const createDeophConfigs = (palette: any) => {
  return {
    color: palette.quintenary.dark || "#EC5BA5",
    content_cards: arrayDeoph,
  };
};

const createDeoJasminBrancoConfigs = (palette: any) => {
  return {
    color: palette.sextenary.dark || "#EC5BA5",
    content_cards: arrayDeoJasmimBranco,
  };
};

const createDeoFlorDePessegueiroConfigs = (palette: any) => {
  return {
    color: palette.septenary.dark || "#EC5BA5",
    content_cards: arrayDeoFlorDePessegueiro,
  };
};

const createWashUrinProtectConfigs = (palette: any) => {
  return {
    color: palette.quartenary.dark || "#EC5BA5",
    content_cards: arrayWashUrinProtect,
  };
};

export const ContentCard: React.FC<IContentCardProps> = ({
  productId,
  color,
}) => {
  const theme = useTheme();
  const { palette } = theme;

  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleArrayBox = (id: number) => {
    switch (id) {
      case 1418:
      case 1420:
        {
          setCardConfigs(createVagisilOBConfigs(palette));
        }
        break;

      case 1421:
        setCardConfigs(createDeophConfigs(palette));
        break;

      case 1426:
        setCardConfigs(createDeoJasminBrancoConfigs(palette));
        break;

      case 1427:
        setCardConfigs(createDeoFlorDePessegueiroConfigs(palette));
        break;

      case 4007:
        setCardConfigs(createWashUrinProtectConfigs(palette));
        break;

      default:
        setCardConfigs(createVagisilOBConfigs(palette));
        break;
    }
  };

  useEffect(() => {
    handleArrayBox(productId);
  }, [productId]);

  return (
    <>
      {productId !== 1012 && (
        <Box>
          {/* <Box px={2} mb={4}>
            <Typography
              variant="h4"
              align="center"
              fontFamily="Addington CF"
              fontWeight={700}
            >
              Desperte sua melhor versão
            </Typography>
          </Box> */}
          <Box px={2}>
            <Grid container spacing={3} height="100%" justifyContent="center">
              {cardConfigs?.content_cards?.map((v: any, index: number) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={3}
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Card className="card" style={{ width: "100%" }}>
                    <Box sx={{ position: "relative", width: "100%" }}>
                      <CardMedia
                        component="img"
                        src={v.bgImage}
                        alt="Imagem do card"
                        sx={{ filter: "brightness(100%)" }}
                      />
                      <Box className="iconCard" aria-label="Ícone de tempo">
                        <img height={80} width={80} src={v.icon} alt="" />
                      </Box>
                    </Box>
                    <CardContent>
                      {v.title && (
                        <Typography
                          align="center"
                          variant="h5"
                          component="div"
                          color={cardConfigs.color ?? "#00859A"}
                          fontFamily="Addington CF"
                          fontWeight={700}
                        >
                          {v.title}
                        </Typography>
                      )}
                      {v.description && (
                        <Typography align="center" variant="body2" color="text">
                          {v.description}
                        </Typography>
                      )}
                    </CardContent>

                    <Box className="lineBoxContainer">
                      <Box
                        className="lineBoxItem"
                        style={{
                          border: `1px solid ${cardConfigs.color ?? "#00859A"}`,
                        }}
                      />
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
