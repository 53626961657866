import { DefaultChip } from "../components";

import transparent from "../assets/icon_partial.png";
import shades from "../assets/shades.svg";
import fullCoverage from "../assets/icon_full.png";

export function HandleProductsChips(category) {
  switch (category) {
    case "Mistura instantânia":
      return <DefaultChip avatar={transparent} label="Mistura instantânia" />;

    case "Para todas as cores de cabelo":
      return (
        <DefaultChip
          avatar={transparent}
          label="Para todas as cores de cabelo"
        />
      );

    case "1 único tom tom para todos os tipos de grisalhos":
      return (
        <DefaultChip
          avatar={shades}
          label="1 único tom tom para todos os tipos de grisalhos"
        />
      );

    case "Cobertura total":
      return (
        <DefaultChip
          avatar={fullCoverage}
          label="Cobertura total"
        />
      );

    default:
      return <DefaultChip avatar={transparent} label={category} />;
  }
}

export function handleLightColor(corHex, percent) {
  let r = parseInt(corHex.substring(1, 3), 16);
  let g = parseInt(corHex.substring(3, 5), 16);
  let b = parseInt(corHex.substring(5, 7), 16);

  r = Math.min(255, r + Math.round(255 * (percent / 100)));
  g = Math.min(255, g + Math.round(255 * (percent / 100)));
  b = Math.min(255, b + Math.round(255 * (percent / 100)));

  const newColorHex = `#${(r < 16 ? '0' : '') + r.toString(16)}${
    (g < 16 ? '0' : '') + g.toString(16)
  }${(b < 16 ? '0' : '') + b.toString(16)}`;

  return newColorHex;
}

export function handleDarkColor(corHex, percent) {
  let r = parseInt(corHex.substring(1, 3), 16);
  let g = parseInt(corHex.substring(3, 5), 16);
  let b = parseInt(corHex.substring(5, 7), 16);

  r = Math.max(0, r - Math.round(255 * (percent / 100)));
  g = Math.max(0, g - Math.round(255 * (percent / 100)));
  b = Math.max(0, b - Math.round(255 * (percent / 100)));

  const newColorHex = `#${(r < 16 ? '0' : '') + r.toString(16)}${
    (g < 16 ? '0' : '') + g.toString(16)
  }${(b < 16 ? '0' : '') + b.toString(16)}`;

  return newColorHex;
}

