import code1418 from "../../assets/vagisil/odorBlock_wash_1418.jpg";
import code1420 from "../../assets/vagisil/odorblock_spray_1420.jpg";
import code1421 from "../../assets/vagisil/1421_Deo_PH.png";
import code1426 from "../../assets/vagisil/1426_Jasmim_branco.jpg";
import code1427 from "../../assets/vagisil/DEO_1427_FP.png";
import code4007 from "../../assets/vagisil/4007_sabonete_urin_protect.png";

import code1418_feature from "../../assets/vagisil/featured_images/vagisilOB_1.png";

export const ProductsMock = [
  {
    id: 1418,
    images: [code1418],
    featured_image: code1418,
    title: "Sabonete Líquido Odor Block",
    subtitle:
      "A tecnologia Advanced Odor Block® ajuda a impedir que o odor aconteça.",
    composition:
      "Água, Sulfonato de Olefina C14-16 Sódico, Cocoanfodiacetato Dissódico, Lauramidopropilbetaína, Cloreto De Sódio, Benzoato de Sódio, Ácido Cítrico, Perfume, Amil Cinamal, Salicilato De Benzila, Eugeno.",
    path: "vagisilwashob",
  },
  {
    id: 1420,
    images: [code1420],
    featured_image: code1420,
    title: "Desodorante Spray OB",
    subtitle:
      "A tecnologia avançada Odor Block® oferece frescor o dia todo. Basta pulverizar e pronto.",
    composition:
      "Água, Propilenoglicol, Óleo de Rícino Hidrogenado Etoxilado, Perfume, Edetato Dissódico, Acetato de Tocoferila, Extrato da Folha de Aloe Barbadensis, Diazolidinil Ureia, Metilparabeno, Propilparabeno, Salicilato de Benzila, Hidróxi-isoexil 3-cicloexeno Carboxaldeído, Linalol, Limoneno.",
    path: "vagisilsprayob",
  },

  {
    id: 1421,
    images: [code1421],
    featured_image: code1421,
    title: "Desodorante Aerosol pH",
    subtitle: "Previne odores respeitando o pH vulvar natural.",
    composition:
      "Butano, Isobutano, Propano, Oleato de Decila, Perfume, Salicilato de Benzila, Butilfenil Metilpropional, Geraniol, Hidroxicitronelal, Hidróxi-Isoexil 3-Cicloexeno Carboxialdeído, Limoneno, Linalol.",
    path: "deoph",
  },
  {
    id: 1426,
    images: [code1426],
    featured_image: code1426,
    title: "Aerosol Essências Delicadas Jasmim Branco",
    subtitle:
      "Explodindo com nosso aroma de jasmim branco, amigo da pele, para um frescor instantâneo a qualquer hora, em qualquer lugar.",
    composition: "Butano, Isobutano, Propano, Oleato de Decila, Perfume.",
    path: "deojasminbranco",
  },
  {
    id: 1427,
    images: [code1427],
    featured_image: code1427,
    title: "Aerosol Essências Delicadas Flor de Pessegueiro",
    subtitle:
      "Explodindo com nosso Perfume Flor de Pessegueiro suave para a pele para um frescor instantâneo a qualquer hora, em qualquer lugar.",
    composition: "Butano, Isobutano, Propano, Oleato de Decila, Perfume. ",
    path: "deoflordepessegueiro",
  },
  {
    id: 4007,
    images: [code4007],
    featured_image: code4007,
    title: "Sabonete Urin Protect",
    subtitle: "Até 24 horas de proteção contra odores.",
    composition:
      "Água, Sulfonato de Olefina C14-16 Sódico, Cocoanfodiacetato Dissódico, Lauramidopropilbetaína, Cloreto de Sódio, Benzoato de Sódio, Ácido Cítrico, Perfume, Amil Cinamal, Salicilato de Benzila, Eugenol.",
    path: "washurinprotect",
  },
];
