import { useEffect, useState } from "react";

import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import bisnaga from "../../../../shared/assets/products/bisnaga_grecin.png";
import locao from "../../../../shared/assets/products/grecin2000_locao.png";
import shampoo_color from "../../../../shared/assets/products/shampoo_color.png";

import backgroundFallSmallVagisilOB from "../../../../shared/assets/background_fall_small_vagisil_OB.svg";
import backgroundFallSmallDeoph from "../../../../shared/assets/background_fall_small_deoph.svg";
import backgroundFallSmallDeojasminbranco from "../../../../shared/assets/background_fall_small_deojasmimbranco.svg";
import backgroundFallSmallDeoflordepessegueiro from "../../../../shared/assets/background_fall_small_deoflordepessegueiro.svg";
import backgroundFallSmallWashurinprotect from "../../../../shared/assets/background_fall_small_washurinprotect.svg";

import "./styles.css";

interface ICompositionCardProps {
  productId: number;
  composition: string;
  activator?: string;
}

const createVagisilOBConfigs = (palette: any) => {
  return {
    divider_color: palette.tertiary.dark || "#EC5BA5",
    svg_image: backgroundFallSmallVagisilOB,
    image_composition: "",
  };
};

const createDeophConfigs = (palette: any) => {
  return {
    divider_color: palette.quintenary.dark || "#EC5BA5",
    svg_image: backgroundFallSmallDeoph,
    image_composition: "",
  };
};

const createDeojasminbrancoConfigs = (palette: any) => {
  return {
    divider_color: palette.sextenary.dark || "#EC5BA5",
    svg_image: backgroundFallSmallDeojasminbranco,
    image_composition: "",
  };
};

const createDeoflordepessegueiroConfigs = (palette: any) => {
  return {
    divider_color: palette.septenary.dark || "#EC5BA5",
    svg_image: backgroundFallSmallDeoflordepessegueiro,
    image_composition: "",
  };
};

const createWashurinprotectConfigs = (palette: any) => {
  return {
    divider_color: palette.quartenary.dark || "#EC5BA5",
    svg_image: backgroundFallSmallWashurinprotect,
    image_composition: "",
  };
};

export const CompositionCard: React.FC<ICompositionCardProps> = ({
  productId,
  composition,
  activator,
}) => {
  const theme = useTheme();
  const { breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleCardOptions = (id: number) => {
    switch (id) {
      case 1418:
      case 1420:
        setCardConfigs(createVagisilOBConfigs(palette));
        break;

      case 1421:
        setCardConfigs(createDeophConfigs(palette));
        break;

      case 1426:
        setCardConfigs(createDeojasminbrancoConfigs(palette));
        break;

      case 1427:
        setCardConfigs(createDeoflordepessegueiroConfigs(palette));
        break;

      case 4007:
        setCardConfigs(createWashurinprotectConfigs(palette));
        break;

      default:
        setCardConfigs(createVagisilOBConfigs(palette));
        break;
    }
  };

  useEffect(() => {
    handleCardOptions(productId);
  }, [productId]);

  return (
    <>
      <Box px={2} mb={10}>
        <Typography
          variant="h4"
          align="center"
          fontFamily="Addington CF"
          fontWeight="700"
        >
          Feito com paixão por mulheres, para mulheres
        </Typography>
      </Box>
      <Box
        className="compositionCardContainer"
        sx={{
          background: `url(${cardConfigs?.svg_image})`,
        }}
      >
        <Box sx={{ position: "relative", top: -20 }}>
          <Grid container spacing={2} height="100%" justifyContent="center">
            <Grid item xs={12} sm={!activator ? 11 : 7.5}>
              <Paper elevation={3} className="paperComposition">
                <Box p={2} display="flex" width="100%">
                  <Box
                    className="paperCompositionBox"
                    sx={{
                      border: `1px solid ${cardConfigs?.divider_color}`,
                      width: "100%",
                    }}
                  >
                    <Box width="100%">
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={32}
                        color={cardConfigs?.divider_color}
                        fontFamily="Addington CF"
                      >
                        COMPOSIÇÃO (Português)
                      </Typography>
                      <Typography fontSize={16}>{composition}</Typography>
                    </Box>
                    {cardConfigs?.image_composition && (
                      <Box className="paperImageComposition">
                        <img
                          src={cardConfigs?.image_composition}
                          alt="imagem de composição"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {activator && (
              <Grid item xs={12} sm={4} className="paperCompositionActivator">
                <Paper elevation={3} className="paperComposition">
                  <Box p={2} display="flex">
                    <Box className="paperCompositionBox ">
                      {smDown && (
                        <Box className="paperImageActivatorResponsive">
                          <img
                            src={cardConfigs?.image_composition}
                            alt="imagem de ativador"
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          fontSize={32}
                          color={cardConfigs?.divider_color}
                          fontFamily="Addington CF"
                        >
                          Ativador
                        </Typography>
                        <Typography fontSize={16}>{activator}</Typography>
                      </Box>
                      {!smDown && (
                        <Box className="paperImageActivator">
                          <img
                            src={cardConfigs?.image_composition}
                            alt="bisnaga"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Divider
        sx={{ background: cardConfigs?.divider_color }}
        className="divider"
      />
    </>
  );
};
