import { Avatar, Box, Icon, Typography, useTheme } from "@mui/material";
import "./styles.css";
import { useEffect } from "react";

interface IProductImgProps {
  categoryArray: string[];
  url: string;
  color?: string;
  productId: number;
}

interface IProductCategoriesProps {
  icon: string;
  description: string;
}

export const ProductImg: React.FC<IProductImgProps> = ({
  categoryArray,
  url,
  color,
  productId,
}) => {
  const theme = useTheme();
  const { palette } = theme;

  const HandleProductCategories: React.FC<IProductCategoriesProps> = ({
    icon,
    description,
  }) => {
    return (
      <Box className="categoryItem">
        <Icon
          sx={{
            color: productId === 1050 ? palette.sextenary.main : color,
            marginBottom: "2px",
          }}
        >
          {icon}
        </Icon>
        <Typography sx={{ fontSize: "10px" }} align="center">
          {description}
        </Typography>
      </Box>
    );
  };

  function HandleProductsChips(categoryItem: string) {
    switch (categoryItem) {
      case "Mistura instantânia":
        return (
          <HandleProductCategories
            icon="blur_circular"
            description={categoryItem}
          />
        );

      case "Para todas as cores de cabelo":
        return (
          <HandleProductCategories icon="timer" description={categoryItem} />
        );

      case "1 único tom para todos os tipos de grisalhos":
        return (
          <HandleProductCategories
            icon="view_carousel"
            description="Para todos os tipos de grisalhos"
          />
        );

      case "Cobertura total":
        return (
          <HandleProductCategories icon="circle" description={categoryItem} />
        );

      case "5 mins":
        return (
          <HandleProductCategories icon="timer" description={categoryItem} />
        );

      default:
        return (
          <HandleProductCategories icon="circle" description={categoryItem} />
        );
    }
  }

  return (
    <>
      <Avatar
        className="avatar"
        sx={{
          background: color ?? "#00859A",
          padding: theme.spacing(1),
        }}
      >
        <Box className="categoryContainer">
          {categoryArray.length > 0 && (
            <Box className="categoryBoxItem">
              {categoryArray.map((v, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "75px",
                    height: "75px",
                    margin: "8px 0",
                    marginRight: -3,
                    marginLeft: 2,
                  }}
                >
                  {HandleProductsChips(v)}
                </Box>
              ))}
            </Box>
          )}
          {url && (
            <Box display="flex" alignItems="center" height="100%">
              <img src={url} alt="" />
            </Box>
          )}
        </Box>
      </Avatar>
    </>
  );
};
