import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  FooterVagisil,
  HeaderMenu,
  ShareButtons,
} from "../../shared/components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../../shared/services/products/producs";
import { ProductImg } from "./components/product-img/productImg";
import { ProductColor } from "./components/product-color/productColor";
import { CompositionCard } from "./components/composition-card/compositionCard";
import { ContentCard } from "./components/content-card/contentCard";
import { ProductSlider } from "./components/product-slider/productSlider";

import logoVagisil from "../../shared/assets/logo_vagisil.png";
import logoVagisil1421 from "../../shared/assets/logos/logo_1421_Deo_PH.png";
import logoVagisil1426 from "../../shared/assets/logos/logo_1426_jasmin_branco.png";
import logoVagisil1427 from "../../shared/assets/logos/logo_1427_flor_pesseg.png";
import logoVagisil4007 from "../../shared/assets/logos/logo_4007_sabon_urin_protect.png";

import "./styles.css";
import { primary } from "./../../shared/themes/light";

export const ProductsView: React.FC = () => {
  const theme = useTheme();
  const { breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const { path } = useParams<string>();
  const [initialDataFromApi, setInitialDataFromApi] = useState<any>();

  const [pageType, setPageType] = useState<any>();

  const loadProduct = () => {
    const product = getProducts(path as string);
    if (product) {
      setInitialDataFromApi(product);
      handleTypePage(path);
    } else navigate("/");
  };

  const handleTitle = (title: string) => {
    if (!title) return title;
    if (!title.includes("/n")) {
      return (
        <Typography
          variant={smDown ? "h4" : "h3"}
          sx={{ fontFamily: "Addington CF", fontWeight: 700 }}
        >
          {title}
        </Typography>
      );
    }
    const newTitle = title.split("/n");
    return (
      <>
        <Typography
          variant={smDown ? "h4" : "h3"}
          sx={{ fontFamily: "Addington CF", fontWeight: 700 }}
        >
          {newTitle[0]}
        </Typography>
        <Typography variant="h6" mb={2} sx={{ fontWeight: 700 }}>
          {newTitle[1]}
        </Typography>
      </>
    );
  };

  function handleTypePage(p: string | undefined) {
    if (p?.includes("vagisil")) {
      setPageType({
        type: "vagisil",
        background: {
          primary: palette.tertiary.main,
          secondary: palette.tertiary.dark,
        },
        logo: logoVagisil4007,
      });
    } else if (p?.includes("deoph")) {
      setPageType({
        type: "deoph",
        background: {
          primary: palette.quintenary.main,
          secondary: palette.quintenary.dark,
        },
        color: palette.quintenary.main,
        logo: logoVagisil1421,
      });
    } else if (p?.includes("deojasminbranco")) {
      setPageType({
        type: "deojasminbranco",
        background: {
          primary: palette.sextenary.main,
          secondary: palette.sextenary.dark,
        },
        color: palette.sextenary.main,
        logo: logoVagisil1426,
      });
    } else if (p?.includes("deoflordepessegueiro")) {
      setPageType({
        type: "deoflordepessegueiro",
        background: {
          primary: `linear-gradient(to bottom right,${palette.septenary.dark} 10%, ${palette.septenary.main} 100%)`,
          secondary: palette.septenary.dark,
        },
        color: palette.septenary.main,
        logo: logoVagisil1427,
      });
    } else if (p?.includes("washurinprotect")) {
      setPageType({
        type: "washurinprotect",
        background: {
          primary: palette.quartenary.main,
          secondary: palette.quartenary.dark,
        },
        color: palette.quartenary.main,
        logo: logoVagisil4007,
      });
    } else
      setPageType({
        type: "default",
        background: {
          primary: palette.tertiary.main,
          secondary: palette.tertiary.dark,
        },
        logo: logoVagisil4007,
      });
  }

  useEffect(() => {
    loadProduct();
  }, [path]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <HeaderMenu
        background={pageType?.background.primary ?? palette.tertiary.main}
        logo={pageType?.logo ?? logoVagisil}
      />
      {initialDataFromApi && (
        <Divider
          sx={{ background: pageType?.background?.secondary, height: "16px" }}
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={2}
      >
        <Box maxWidth="1440px" width="100%" height="100%">
          {!smDown && (
            <Box px={2} mt={-2} display="flex" justifyContent="flex-end">
              <ShareButtons color={pageType?.background?.secondary} />
            </Box>
          )}
          <Grid container>
            <Grid item xs={10} sm={6} md={4} py={2} pl={10}>
              <Box className="productTitleContainer">
                {handleTitle(initialDataFromApi?.title)}
                <p>{initialDataFromApi?.subtitle}</p>
              </Box>
            </Grid>
            {smDown && (
              <Grid item xs={2} pr={2} pt={2}>
                <ShareButtons color={pageType?.background?.secondary} />
              </Grid>
            )}
            {initialDataFromApi && (
              <Grid item xs={12} sm={6} md={4.5}>
                <Box className="productImageContainer">
                  <ProductImg
                    url={initialDataFromApi?.featured_image}
                    categoryArray={initialDataFromApi?.categories ?? []}
                    color={pageType?.background?.primary}
                    productId={initialDataFromApi?.id}
                  />
                </Box>
              </Grid>
            )}
            {/* {![1012, 1050].includes(initialDataFromApi?.id) && (
              <Grid item xs={12} sm={3} md={3.5} pl={2} py={2}>
                <ProductColor productId={initialDataFromApi?.id} />
              </Grid>
            )} */}
          </Grid>

          <Box my={6}>
            <CompositionCard
              productId={initialDataFromApi?.id}
              composition={initialDataFromApi?.composition}
              activator={initialDataFromApi?.activator_composition}
            />
          </Box>

          <Box my={6}>
            <ContentCard productId={initialDataFromApi?.id} />
          </Box>

          <Box mt={6} mb={25}>
            <ProductSlider
              imageList={initialDataFromApi?.images}
              productId={initialDataFromApi?.id}
            />
          </Box>

          <Box px={2} mb={12}>
            <Typography
              variant="h4"
              align="center"
              fontFamily="Addington CF"
              fontWeight={700}
            >
              Disponível em todo o Brasil
            </Typography>
          </Box>
        </Box>
      </Box>
      <FooterVagisil
        background={pageType?.background?.primary ?? palette.tertiary.main}
      />
    </Box>
  );
};
