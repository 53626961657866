import { useEffect, useState } from "react";

import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { SliderCenter } from "../../../../shared/components";

import backgroundFallVagisilOB from "../../../../shared/assets/background_fall_vagisil_OB.svg";
import backgroundFallDeoph from "../../../../shared/assets/background_fall_deoph.svg";
import backgroundFallDeojasminbranco from "../../../../shared/assets/background_fall_deojasmimbranco.svg";
import backgroundFallDeoflordepessegueiro from "../../../../shared/assets/background_fall_deoflordepessegueiro.svg";
import backgroundFallWashurinprotect from "../../../../shared/assets/background_fall_washurinprotect.svg";

interface IProductSliderProps {
  imageList: string[];
  productId: number;
}

const createVagisilOBConfigs = (palette: any) => {
  return {
    divider_color: palette.tertiary.dark || "#EC5BA5",
    svg_image: backgroundFallVagisilOB,
  };
};

const createDeophConfigs = (palette: any) => {
  return {
    divider_color: palette.quintenary.dark || "#EC5BA5",
    svg_image: backgroundFallDeoph,
  };
};

const createDeojasminbrancoConfigs = (palette: any) => {
  return {
    divider_color: palette.sextenary.dark || "#EC5BA5",
    svg_image: backgroundFallDeojasminbranco,
  };
};

const createDeoflordepessegueiroConfigs = (palette: any) => {
  return {
    divider_color: palette.septenary.dark || "#EC5BA5",
    svg_image: backgroundFallDeoflordepessegueiro,
  };
};

const createWashurinprotectConfigs = (palette: any) => {
  return {
    divider_color: palette.quartenary.dark || "#EC5BA5",
    svg_image: backgroundFallWashurinprotect,
  };
};

export const ProductSlider: React.FC<IProductSliderProps> = ({
  imageList,
  productId,
}) => {
  const theme = useTheme();
  const { breakpoints, palette } = theme;
  const smDown = useMediaQuery(breakpoints.down("sm"));

  const [cardConfigs, setCardConfigs] = useState<any>();

  const handleCardOptions = (id: number) => {
    switch (id) {
      case 1418:
      case 1420:
        setCardConfigs(createVagisilOBConfigs(palette));
        break;

      case 1421:
        setCardConfigs(createDeophConfigs(palette));
        break;

      case 1426:
        setCardConfigs(createDeojasminbrancoConfigs(palette));
        break;

      case 1427:
        setCardConfigs(createDeoflordepessegueiroConfigs(palette));
        break;

      case 4007:
        setCardConfigs(createWashurinprotectConfigs(palette));
        break;

      default:
        setCardConfigs(createVagisilOBConfigs(palette));
        break;
    }
  };

  useEffect(() => {
    handleCardOptions(productId);
  }, [productId]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          background: `url(${cardConfigs?.svg_image}) no-repeat bottom`,
          backgroundSize: "cover",
          height: 460,
          maxHeight: 460,
        }}
      >
        <Box paddingTop={smDown ? 13 : 9}>
          <SliderCenter
            slides={imageList ?? []}
            color={cardConfigs?.divider_color}
          />
        </Box>
      </Box>
      <Divider
        sx={{ background: cardConfigs?.divider_color, height: "16px" }}
      />
    </>
  );
};
