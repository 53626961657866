import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import { Box, Divider, useTheme } from "@mui/material";

interface ISlideProps {
  slides: Array<string>;
  dividerColor?: string;
}

export const Slider: React.FC<ISlideProps> = ({ slides, dividerColor }) => {
  const theme = useTheme();
  if (!dividerColor) dividerColor = theme.palette.text.primary;

  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    //   loop={true}
    pagination: {
      el: ".swiper-default-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-default-button-next",
      prevEl: ".swiper-default-button-prev",
    },
    modules: [Pagination, Navigation],
  };

  return (
    <>
      <Swiper
        {...params}
        style={{ paddingBottom: slides.length > 1 ? "40px" : "0" }}
        className="swiper-default"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img alt="Produtos Grecin" src={slide} />
          </SwiperSlide>
        ))}

        <Box className="swiper-button-prev swiper-default-button-prev"></Box>
        <Box className="swiper-button-next swiper-default-button-next"></Box>

        <Box className="swiper-pagination swiper-default-pagination"></Box>
      </Swiper>
      <Divider color={dividerColor} sx={{ height: theme.spacing(1) }} />
    </>
  );
};
