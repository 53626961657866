// import { createTheme, alpha } from "@mui/material";
// import { cyan, yellow } from "@mui/material/colors";

import { createTheme, getContrastRatio } from "@mui/material/styles";
import { handleDarkColor, handleLightColor } from "../utils";

export const primary = "#2D2926";
export const secondary = "#315496";
export const tertiary = "#EC5BA5";

export const quartenary = "#00BAB3";
export const quintenary = "#1CA7C1";
export const sextenary = "#F9BF0C";
export const septenary = "#FD7F6E";

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: handleLightColor(primary, 20),
      dark: handleDarkColor(primary, 20),
      contrastText: getContrastRatio(primary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    secondary: {
      main: secondary,
      light: handleLightColor(secondary, 20),
      dark: handleDarkColor(secondary, 20),
      contrastText: getContrastRatio(secondary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    tertiary: {
      main: tertiary,
      light: handleLightColor(tertiary, 20),
      dark: handleDarkColor(tertiary, 20),
      contrastText: getContrastRatio(tertiary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    quartenary: {
      main: quartenary,
      light: handleLightColor(quartenary, 20),
      dark: handleDarkColor(quartenary, 20),
      contrastText:
        getContrastRatio(quartenary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    quintenary: {
      main: quintenary,
      light: handleLightColor(quintenary, 20),
      dark: handleDarkColor(quintenary, 20),
      contrastText:
        getContrastRatio(quintenary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    sextenary: {
      main: sextenary,
      light: handleLightColor(sextenary, 20),
      dark: handleDarkColor(sextenary, 20),
      contrastText: getContrastRatio(sextenary, "#fff") > 4.5 ? "#fff" : "#111",
    },
    septenary: {
      main: septenary,
      light: handleLightColor(septenary, 20),
      dark: handleDarkColor(septenary, 20),
      contrastText: getContrastRatio(septenary, "#fff") > 4.5 ? "#fff" : "#111",
    },

    background: {
      paper: "#ffffff",
      default: "#ffffff",
      // default: "#f7f6f3",
    },
    text: {
      primary: "#2D2926",
      secondary: "#305395",
    },
  },
  typography: {
    fontFamily: [
      "Acherus Grotesque",
      "Addington CF",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

// Univers LT Std
